import 'mapbox-gl/dist/mapbox-gl.css';

import { CurrentPositionPin } from '@application/assets';
import {
  ExtendMapControl,
  clusterCountLayer,
  clusterLayer,
  comingSoonNotIncludedPointLayer,
  comingSoonPointLayer,
  trackedNotIncludedPointLayer,
  trackedPointLayer,
  untrackedNotIncludedPointLayer,
  untrackedPointLayer,
} from '@application/components';
import { Site } from '@domain/graphql.types';
import { Box, useTheme } from '@mui/material';
import { useCallback, useContext, useRef } from 'react';
import ReactMap, { Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { useResizeObserver } from 'usehooks-ts';
import MapPin from '../components/MapPin';
import SiteLookupContext from '../context/SiteLookupContext';
import SiteLookupMapContext from '../context/SiteLookupMapContext';
import LegendMapControl from './LegendMapControl';
import ContextMenu from './contextmenu/SiteLookupMapContextMenu';
import useSiteLookupMapContextMenu from './contextmenu/useSiteLookupMapContextMenu';
import useSiteLookupMap from './useSiteLookupMap';

type Props = {
  fetchingSites: boolean;
  sites?: readonly Site[];
};

const SiteLookupMap = ({ fetchingSites, sites }: Props) => {
  const containerRef = useRef<HTMLElement>(null);

  const { isMapExtended, toggleIsMapExtended } = useContext(SiteLookupMapContext);
  const {
    filters: { address, radius: searchRadius },
    updateFilterAddress,
  } = useContext(SiteLookupContext);
  const center = address?.coordinates;
  const showRadiusArea = !!center;

  const {
    actions: { setSelectedSite },
    layers: { radiusCircle },
    map: { accessToken, style },
    refs: { map },
    state: { allSites, displayExtendControl, mapCenter, mapZoom, selectedSite },
  } = useSiteLookupMap({ center, searchRadius, sites });

  const {
    actions: { close: closeContextMenu, copyCoordinatesToClipboard, open: openContextMenu, searchNearby },
    state: { coordinates, point, visible: contextMenuIsVisible },
  } = useSiteLookupMapContextMenu({
    onSearch: ({ lat: latitude, lng: longitude, fullAddress, regionCode }: { lat: number; lng: number; fullAddress: string; regionCode: string }) =>
      updateFilterAddress({
        coordinates: {
          latitude,
          longitude,
        },
        fullAddress,
        regionCode,
      }),
  });

  const {
    palette: { primary },
  } = useTheme();

  const onResize = useCallback(() => {
    map.current?.resize();
  }, [map]);

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useResizeObserver({ ref: containerRef, onResize });

  return (
    <Box ref={containerRef} sx={{ height: '100%', width: '100%' }}>
      <ReactMap
        ref={map}
        mapStyle={style}
        initialViewState={{
          latitude: mapCenter.latitude,
          longitude: mapCenter.longitude,
          zoom: mapZoom,
        }}
        dragRotate={false}
        mapboxAccessToken={accessToken}
        onContextMenu={openContextMenu}
        onClick={closeContextMenu}
      >
        {showRadiusArea && (
          <Source id="map-date-source" type="geojson" data={radiusCircle}>
            <Layer
              beforeId="clusters"
              id="center-radius-circle"
              type="fill"
              paint={{
                'fill-color': primary.dark,
                'fill-opacity': 0.12,
              }}
            />

            <Layer
              beforeId="clusters"
              id="center-radius-outer-line"
              type="line"
              paint={{
                'line-width': 2,
                'line-color': primary.main,
              }}
            />

            {!fetchingSites &&
              sites?.map(({ id, coordinates: { latitude, longitude }, includedInReport, status, trackingState }) => (
                <Marker
                  key={id}
                  onClick={() => setSelectedSite({ id, origin: 'map' })}
                  latitude={latitude}
                  longitude={longitude}
                  scale={1}
                  style={{ cursor: 'pointer', zIndex: selectedSite?.id === id ? 10 : 'unset' }}
                >
                  <MapPin isSelected={selectedSite?.id === id} includedInReport={includedInReport} state={trackingState} status={status} />
                </Marker>
              ))}
            <Marker latitude={mapCenter.latitude} longitude={mapCenter.longitude} scale={1} style={{ zIndex: 5 }}>
              <CurrentPositionPin />
            </Marker>
          </Source>
        )}

        <Source id="all_sites" type="geojson" data={allSites} cluster={true} clusterMaxZoom={14} clusterRadius={50}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...comingSoonPointLayer} />
          <Layer {...comingSoonNotIncludedPointLayer} />
          <Layer {...trackedPointLayer} />
          <Layer {...trackedNotIncludedPointLayer} />
          <Layer {...untrackedPointLayer} />
          <Layer {...untrackedNotIncludedPointLayer} />
        </Source>

        <NavigationControl position="bottom-right" showCompass={false} />

        {displayExtendControl && <ExtendMapControl handleToggleMapExtended={toggleIsMapExtended} isMapExtended={isMapExtended} />}

        <LegendMapControl />
        {contextMenuIsVisible && (
          <ContextMenu
            coordinates={coordinates}
            isOpen
            onClose={closeContextMenu}
            onCoordinates={copyCoordinatesToClipboard}
            onSearch={searchNearby}
            position={point}
          />
        )}
      </ReactMap>
    </Box>
  );
};

export default SiteLookupMap;
